<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :src="barImage"
    mobile-break-point="960"
    app
    mini-variant-width="80"
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img :gradient="`to bottom, ${barColor}`" v-bind="props" />
    </template>

    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="text-uppercase font-weight-regular display-2">
          <span class="logo-mini">G</span>
          <span class="logo-normal">GOUPEE</span>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider class="mb-1" />

    <v-list dense nav>
      <base-item class="item-sidebar" :item="profile" />
    </v-list>

    <v-divider class="mb-2" />

    <v-list expand nav>
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <template v-for="(item, i) in computedItems">
        <!-- -->
        <template v-if="showInSidebar(item.roles)">
          <base-item-group
            class="item-sidebar"
            v-if="item.children"
            :key="`group-${i}`"
            :item="item"
          >
            <!--  -->
          </base-item-group>

          <base-item
            v-else
            :key="`item-${i}`"
            :item="item"
            :class="item.to ? '' : 'header-item'"
          />
        </template>
      </template>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>
  </v-navigation-drawer>
</template>
<script>
const roleUsers = [
  "manager_read",
  "manager_create",
  "manager_update",
  "manager_delete",
  "customer_read",
  "customer_create",
  "customer_update",
  "customer_delete",
];

const roleGroups = [
  "group_create",
  "group_read",
  "group_update",
  "group_delete",
];

const roleQuestions = [
  "question_read",
  "question_create",
  "question_update",
  "question_delete",
  "category_read",
  "category_create",
  "category_update",
  "category_delete",
  "answer_read",
  "answer_update",
  "answer_delete",
  "field_read",
  "field_create",
  "field_update",
  "field_delete",
  "subject_read",
  "subject_create",
  "subject_update",
  "subject_delete",
  "country_read",
  "country_create",
  "country_update",
  "country_delete",
  "language_read",
  "language_create",
  "language_update",
  "language_delete",
  "organization_read",
  "organization_create",
  "organization_update",
  "organization_delete",
  "position_read",
  "position_create",
  "position_update",
  "position_delete",
  "age_read",
  "age_create",
  "age_update",
  "age_delete",
  "tag_read",
  "tag_create",
  "tag_update",
  "tag_delete",
];

const roleTransactions = [
  "transaction_read",
  "transaction_create",
  "transaction_update",
  "transaction_delete",
  "recharge_read",
  "recharge_create",
  "recharge_update",
  "recharge_delete",
  "withdraw_read",
  "withdraw_create",
  "withdraw_update",
  "withdraw_delete",
];

const roleInformations = [
  "question_answer_read",
  "question_answer_create",
  "question_answer_update",
  "question_answer_delete",
  "shipping_method_read",
  "shipping_method_create",
  "shipping_method_update",
  "shipping_method_delete",
  "payment_read",
  "payment_create",
  "payment_update",
  "payment_delete",
  "regular_read",
  "regular_create",
  "regular_update",
  "regular_delete",
  "privacy_policy_read",
  "privacy_policy_create",
  "privacy_policy_update",
  "privacy_policy_delete",
  "about_us_read",
  "about_us_create",
  "about_us_update",
  "about_us_delete",
];

const roleWebsite = [
  "partner_read",
  "partner_create",
  "partner_update",
  "partner_delete",
  "evaluation_read",
  "evaluation_create",
  "evaluation_update",
  "evaluation_delete",
  "filter_price_read",
  "filter_price_create",
  "filter_price_update",
  "filter_price_delete",
];

const roleDataSetting = [
  "role_read",
  "role_create",
  "role_update",
  "role_delete",
  "notification_read",
  "notification_create",
  "notification_update",
  "notification_delete",
  "notification_customer_read",
  "notification_customer_create",
  "notification_customer_update",
  "notification_customer_delete",
  "social_read",
  "social_create",
  "social_update",
  "social_delete",
];

// Utilities
import { mapState } from "vuex";
import TokenService from "../../helpers/token";
import { checkMobile } from "../../helpers/utils";

export default {
  name: "DashboardCoreDrawer",
  data: () => ({
    items: [
      {
        icon: "mdi-view-dashboard",
        title: "Dashboard",
        to: "/dashboard",
        roles: ["dashboard_read"],
      },
      {
        to: "",
        title: "Tài khoản",
        roles: roleUsers,
      },
      {
        group: "/accounts",
        icon: "mdi-account",
        title: "Quản lý tài khoản",
        roles: roleUsers,
        children: [
          {
            title: "Người dùng",
            to: "customer-list",
            roles: [
              "customer_read",
              "customer_create",
              "customer_update",
              "customer_delete",
            ],
          },
          {
            title: "Nội bộ",
            to: "manager-list",
            roles: [
              "manager_read",
              "manager_create",
              "manager_update",
              "manager_delete",
            ],
          },
        ],
      },
      {
        to: "",
        title: "Nhóm",
        roles: roleGroups,
      },
      {
        group: "/groups",
        icon: "mdi-order-bool-ascending-variant",
        title: "Quản lý nhóm",
        roles: roleGroups,
        children: [
          {
            title: "Danh sách nhóm",
            to: "group-list",
            roles: [
              "group_read",
              "group_create",
              "group_update",
              "group_delete",
            ],
          },
        ],
      },
      {
        to: "",
        title: "Câu hỏi",
        roles: roleQuestions,
      },
      {
        group: "/questions",
        icon: "mdi-file-document-edit-outline",
        title: "Quản lý câu hỏi",
        roles: roleQuestions,
        children: [
          {
            title: "Câu hỏi",
            to: "question-list",
            roles: [
              "question_read",
              "question_create",
              "question_update",
              "question_delete",
              "question_download_upload",
            ],
          },
          {
            title: "Bình luận",
            to: "comment",
            roles: ["answer_read", "answer_update", "answer_delete"],
          },
          {
            title: "Danh mục",
            to: "category",
            roles: [
              "category_read",
              "category_create",
              "category_update",
              "category_delete",
            ],
          },
          {
            title: "Lĩnh vực",
            to: "field",
            roles: [
              "field_read",
              "field_create",
              "field_update",
              "field_delete",
            ],
          },
          {
            title: "Môn học",
            to: "subject",
            roles: [
              "subject_read",
              "subject_create",
              "subject_update",
              "subject_delete",
            ],
          },
          {
            title: "Quốc gia",
            to: "country",
            roles: [
              "country_read",
              "country_create",
              "country_update",
              "country_delete",
            ],
          },
          {
            title: "Ngôn ngữ",
            to: "language",
            roles: [
              "language_read",
              "language_create",
              "language_update",
              "language_delete",
            ],
          },
          {
            title: "Tổ chức",
            to: "organization",
            roles: [
              "organization_read",
              "organization_create",
              "organization_update",
              "organization_delete",
            ],
          },
          {
            title: "Vị trí - Chức  vụ",
            to: "position",
            roles: [
              "position_read",
              "position_create",
              "position_update",
              "position_delete",
            ],
          },
          {
            title: "Độ tuổi",
            to: "age",
            roles: ["age_read", "age_create", "age_update", "age_delete"],
          },
          {
            title: "Nhãn",
            to: "tag",
            roles: ["tag_read", "tag_create", "tag_update", "tag_delete"],
          },
        ],
      },
      {
        to: "",
        title: "Quảng cáo",
        roles: [
          "banner_read",
          "banner_create",
          "banner_update",
          "banner_delete",
        ],
      },
      {
        group: "/banners",
        icon: "mdi-arrow-decision-outline",
        title: "Quản lý banner",
        roles: [
          "banner_read",
          "banner_create",
          "banner_update",
          "banner_delete",
        ],
        children: [
          {
            title: "Thiết lập danh sách",
            to: "banner-list",
            roles: [
              "banner_read",
              "banner_create",
              "banner_update",
              "banner_delete",
            ],
          },
        ],
      },
      {
        to: "",
        title: "Lịch sử",
        roles: ["behavior_customer_read", "behavior_user_read"],
      },
      {
        group: "/actions",
        icon: "mdi-clipboard-text-clock-outline",
        title: "Lịch sử hoạt động",
        roles: ["behavior_customer_read", "behavior_user_read"],
        children: [
          {
            title: "người dùng",
            to: "customer",
            roles: ["behavior_customer_read"],
          },
          {
            title: "Nội bộ",
            to: "user",
            roles: ["behavior_user_read"],
          },
        ],
      },
      {
        to: "",
        title: "Chính sách",
        roles: roleInformations,
      },
      {
        group: "/information",
        icon: "mdi-information-outline",
        title: "Thông tin chính sách",
        roles: roleInformations,
        children: [
          {
            title: "Câu hỏi thường gặp",
            to: "question-answer",
            roles: [
              "question_answer_read",
              "question_answer_create",
              "question_answer_update",
              "question_answer_delete",
            ],
          },
          {
            title: "Chính sách giao hàng",
            to: "shipping-method",
            roles: [
              "shipping_method_read",
              "shipping_method_create",
              "shipping_method_update",
              "shipping_method_delete",
            ],
          },
          {
            title: "Phương thức thanh toán",
            to: "payment",
            roles: [
              "payment_read",
              "payment_create",
              "payment_update",
              "payment_delete",
            ],
          },
          {
            title: "Chính sách đổi trả",
            to: "regular",
            roles: [
              "regular_read",
              "regular_create",
              "regular_update",
              "regular_delete",
            ],
          },
          {
            title: "Chính sách bảo mật",
            to: "privacy-policy",
            roles: [
              "privacy_policy_read",
              "privacy_policy_create",
              "privacy_policy_update",
              "privacy_policy_delete",
            ],
          },
          {
            title: "Giới thiệu công ty",
            to: "about-us",
            roles: [
              "about_us_read",
              "about_us_create",
              "about_us_update",
              "about_us_delete",
            ],
          },
        ],
      },
      {
        to: "",
        title: "Website",
        roles: roleWebsite,
      },
      {
        group: "/setting-website",
        icon: "mdi-cog-play-outline",
        title: "Dữ liệu website",
        roles: roleWebsite,
        children: [
          {
            title: "Đối tác",
            to: "partner",
            roles: [
              "partner_read",
              "partner_create",
              "partner_update",
              "partner_delete",
            ],
          },
          {
            title: "Cảm nhận người dùng",
            to: "evaluation",
            roles: [
              "evaluation_read",
              "evaluation_create",
              "evaluation_update",
              "evaluation_delete",
            ],
          },
          {
            title: "Lọc theo giá",
            to: "price-filter",
            roles: [
              "filter_price_read",
              "filter_price_create",
              "filter_price_update",
              "filter_price_delete",
            ],
          },
        ],
      },
      {
        to: "",
        title: "Tư vấn - Hỗ trợ",
        roles: [
          "contact_work_read",
          "contact_work_create",
          "contact_work_update",
          "contact_work_delete",
          "consult_read",
          "consult_create",
          "consult_update",
          "consult_delete",
        ],
      },
      {
        icon: "mdi-human-greeting-proximity",
        title: "Phản hồi, góp ý",
        to: "/contact-us",
        roles: [
          "contact_work_read",
          "contact_work_create",
          "contact_work_update",
          "contact_work_delete",
        ],
      },
      {
        icon: "mdi-human-greeting-proximity",
        title: "Khách cần tư vấn",
        to: "/consult",
        roles: [
          "consult_read",
          "consult_create",
          "consult_update",
          "consult_delete",
        ],
      },
      // {
      //   to: "",
      //   title: "Tài liệu",
      //   roles: ["dashboard_read"],
      // },
      // {
      //   icon: "mdi-newspaper-variant-multiple-outline",
      //   title: "Tài liệu hướng dẫn",
      //   to: "/training",
      //   roles: ["dashboard_read"],
      // },
      {
        to: "",
        title: "Giao dịch",
        roles: roleTransactions,
      },
      {
        group: "/transactions",
        icon: "mdi-cash-multiple",
        title: "Quản lý giao dịch",
        roles: roleTransactions,
        children: [
          {
            title: "Nạp tiền",
            to: "recharge-list",
            roles: [
              "recharge_read",
              "recharge_create",
              "recharge_update",
              "recharge_delete",
            ],
          },
          {
            title: "Rút tiền",
            to: "withdraw-list",
            roles: [
              "withdraw_read",
              "withdraw_create",
              "withdraw_update",
              "withdraw_delete",
            ],
          },
          {
            title: "Lịch sử giao dịch",
            to: "transaction-list",
            roles: [
              "transaction_read",
              "transaction_create",
              "transaction_update",
              "transaction_delete",
            ],
          },
        ],
      },
      {
        to: "",
        title: "Cài đặt",
        roles: roleDataSetting,
      },
      {
        group: "/data-setting",
        icon: "mdi-database-cog",
        title: "Cài đặt dữ liệu",
        roles: roleDataSetting,
        children: [
          {
            title: "Phân quyền",
            to: "role",
            roles: ["role_read", "role_create", "role_update", "role_delete"],
          },
          {
            title: "Thông báo",
            to: "notification",
            roles: [
              "notification_read",
              "notification_create",
              "notification_update",
              "notification_delete",
            ],
          },
          {
            title: "Thông báo tới người dùng",
            to: "notification-customer",
            roles: [
              "notification_customer_read",
              "notification_customer_create",
              "notification_customer_update",
              "notification_customer_delete",
            ],
          },
          {
            title: "Mạng xã hội",
            to: "social",
            roles: [
              "social_read",
              "social_create",
              "social_update",
              "social_delete",
            ],
          },
          {
            title: "Hướng dẫn nạp tiền",
            to: "bank",
            roles: [
              "bank_read",
              "bank_create",
              "bank_update",
              "bank_delete",
            ],
          },
          // {
          //   title: "Icon thông báo",
          //   to: "icon",
          //   roles: ["icon_read", "icon_create", "icon_update", "icon_delete"],
          // },
          // {
          //   title: "Thông báo tới người dùng",
          //   to: "notification-customer",
          //   roles: [
          //     "notification_customer_read",
          //     "notification_customer_create",
          //     "notification_customer_update",
          //     "notification_customer_delete",
          //   ],
          // },
        ],
      },
    ],
    expandOnHover: false,
  }),

  created() {
    // this.expandOnHover = !checkMobile();
    // console.log(this.expandOnHover);
    Bus.$on("change-drawer", () => {
      this.expandOnHover = !this.expandOnHover;
    });
  },

  computed: {
    ...mapState(["barColor", "barImage"]),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
    computedItems() {
      return this.items.map(this.mapItem);
    },
    profile() {
      return {
        avatar: true,
        avatarPath: TokenService.getAvatarPath(),
        group: "",
        title: TokenService.getFullName(),
      };
    },
  },

  // watch: {
  //   "$vuetify.breakpoint.smAndDown"(val) {
  //     this.$emit("update:expandOnHover", !val);
  //   },
  // },

  methods: {
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: item.title,
      };
    },
  },
};
</script>

<style lang="css">
a.v-list-item.v-list-item--link.header-item
  .v-list-item__content
  .v-list-item__title {
  font-weight: 900;
  text-transform: uppercase;
}
</style>